import styled from "styled-components";
import { COLORS, FONT_FAMILY, FONT_WEIGHT } from "../../theme";

export const ContainerContact = styled.div`
  width: 768px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) {
    width: 90%;
  }

  form {
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    span {
      font-size: 14px;
      margin-top: 8px;
      color: ${COLORS.BLACK};

      a {
        color: ${COLORS.BLACK};
      }
    }

    div {
      margin-top: 12px;
      display: flex;
      flex-direction: column;

      input,
      textarea {
        resize: none;
        font-size: 16px;
        padding: 24px 12px;
        border: 1px solid rgba(0, 0, 0, 0.2);

        &:focus,
        &:hover {
          outline: none;
          border: 1px solid rgba(0, 0, 0, 0.2);
        }
      }

      &:last-of-type {
        margin-top: 18px;
      }

      span {
        color: red;
        font-size: 14px;
        margin-top: 4px;
        margin-left: 2px;
        font-family: ${FONT_FAMILY.INTER};
        font-weight: ${FONT_WEIGHT.SEMI_BOLD};
      }
    }
  }
`;

export const PageTitle = styled.h1`
  text-transform: uppercase;
  font-size: 48px;
  color: ${COLORS.BLACK};
  font-weight: ${FONT_WEIGHT.BOLD};
  margin-top: 16px;

  @media (max-width: 1420px) {
    font-size: 42px;
    margin-top: 8px;
  }

  @media (max-width: 500px) {
    font-size: 32px;
    margin-top: 16px;
  }
`;

export const PageDesc = styled.p`
  width: 100%;
  text-align: center;
  color: ${COLORS.BLACK};
  margin-top: 16px;
`;

export const BlackBox = styled.div`
  width: 100%;
  background-color: ${COLORS.BLACK};
  margin-top: 32px;
`;

export const BoxBody = styled.div`
  padding: 42px 68px;
  color: ${COLORS.WHITE};

  @media (max-width: 500px) {
    margin: 0 auto;
    padding: 24px 0;
    width: 90%;
  }
`;

export const BoxTitle = styled.p`
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-family: ${FONT_FAMILY.INTER};
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

export const BoxInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;

  @media (max-width: 710px) {
    flex-direction: column;
    align-items: baseline;
  }

  div {
    width: 45%;
    display: flex;
    flex-direction: column;

    @media (max-width: 710px) {
      width: 100%;
    }
  }

  a {
    color: ${COLORS.WHITE};
    text-decoration: none;
    font-size: 18px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};

    &:hover {
      text-decoration: underline;
    }

    img {
      width: 20px;
      margin-right: 8px;
    }
  }
`;
