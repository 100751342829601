import Container from "../../components/Container";
import Content from "../../components/Content";
import ContentPage from "../../components/ContentPage";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Flags from "../../assets/images/flags.webp";

export default function Differences() {
  return (
    <>
      <Header selected="differences" />
      <Container>
        <Content>
          <ContentPage backgroundImage={Flags} text="Por que a Ene?">
            <h2>Nosso conhecimento nos mercados Capixaba e Portugues</h2>
            <h2>Pacote Completo dos serviços</h2>
            <p>
              <li>Inteligência de Mercado</li>
              <li>Estratégia de Mercado</li>
              <li>Jurídico e Tributário</li>
              <li>Investimento</li>
            </p>
            <h2>
              Nossa rede de relacionamento no Espirito Santo e em Portugal
            </h2>
            <p>
              Nossa equipe tem se destacado na construção de um network robusto
              tanto no Espírito Santo quanto em Portugal. No Brasil,
              especificamente no Espírito Santo, a proximidade com o ambiente de
              inovação local, empresas emergentes e indústrias tradicionais,
              fortalece nossas conexões com parceiros estratégicos e
              oportunidades de crescimento.
            </p>
            <p>
              Em Portugal, com um cenário dinâmico e próspero no setor
              tecnológico, expandimos nossas fronteiras, criando pontes com o
              mercado europeu. Essa atuação binacional nos permite acessar uma
              rede diversificada de contatos, gerando sinergias e abrindo portas
              para novas colaborações e projetos inovadores.
            </p>
          </ContentPage>
        </Content>
      </Container>
      <Footer />
    </>
  );
}
