import Content from "../../components/Content";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Title from "../../components/Title";
import {
  BlackBox,
  BoxBody,
  BoxInfo,
  BoxTitle,
  ContainerContact,
  PageDesc,
  PageTitle,
} from "./style";
import Whatsapp from "../../assets/images/whatsapp-w.svg";
import Email from "../../assets/images/email-w.svg";
import Instagram from "../../assets/images/instagram-w.svg";
import Linkedin from "../../assets/images/linkedin-w.svg";
import Button from "../../components/Button";
import { useForm, SubmitHandler } from "react-hook-form";
import { TypeForm, typeSchema } from "./typeSchema";
import { yupResolver } from "../../utils/yupResolver";
import emailjs from "emailjs-com";
import { useMutation } from "@tanstack/react-query";
import { Toast, ToastProps } from "../../components/Toast";
import { useEffect, useState } from "react";
import { email, instagram, linkedin, phoneNumber } from "../../mocks/data";

type SendEmailProps = {
  serviceId: string;
  templateId: string;
  formData: TypeForm;
  userId: string;
};

export default function Contact() {
  const [toastProps, setToastProps] = useState<ToastProps>({
    title: "",
    desc: "",
    open: false,
  });

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm<TypeForm>({
    resolver: yupResolver(typeSchema),
  });

  const handlePhoneChange = (phone: string) => {
    if (phone.startsWith("+55")) {
      setValue(
        "phone",
        phone
          .replace(/[^\d]/g, "")
          .replace(/^(\d\d)(\d)/, "+$1 $2")
          .replace(/(\d{2})(\d)/, "($1) $2")
          .replace(/(\d{5})(\d)/, "$1-$2")
          .substring(0, 19)
      );
    } else if (phone.startsWith("+351")) {
      if (
        phone?.split(" ")?.[1]?.includes("21") ||
        phone?.split(" ")?.[1]?.includes("22")
      ) {
        setValue(
          "phone",
          phone
            .replace(/[^\d]/g, "")
            .replace(/^(\d{3})(\d)/, "+$1 $2")
            .replace(/(\d{2})(\d{3})(\d{3})/, "$1 $2 $3")
            .substring(0, 15)
        );
      } else {
        setValue(
          "phone",
          phone
            .replace(/[^\d]/g, "")
            .replace(/^(\d{3})(\d)/, "+$1 $2")
            .replace(/(\d{3})(\d)/, "$1 $2")
            .replace(/(\d{3})(\d)/, "$1 $2")
            .replace(/(\d{3})(\d)/, "$1 $2")
            .substring(0, 16)
        );
      }
    } else {
      setValue("phone", phone.replace(/[^\d]/g, "").replace(/^(\d)/, "+$1"));
    }
  };

  const { mutate, isLoading } = useMutation(
    ["send-email"],
    ({ serviceId, templateId, formData, userId }: SendEmailProps) =>
      emailjs.send(serviceId, templateId, formData, userId),
    {
      onSuccess: () => {
        reset();
        if (!localStorage?.getItem("send_time")) {
          localStorage.setItem(
            "send_time",
            new Date(new Date().setHours(new Date().getHours() + 1)).toString()
          );
        }
        setToastProps({
          title: "Seu contato foi enviado",
          desc: "Seu e-mail foi enviado com sucesso!",
          open: true,
        });
      },
      onError: () => {
        setToastProps({
          title: "Erro ao enviar contato",
          desc: "Ocorreu um erro ao tentar enviar o e-mail, por favor tente novamente!",
          open: true,
        });
      },
    }
  );

  const onSubmit: SubmitHandler<TypeForm> = async (formData) => {
    if (
      !!localStorage?.getItem("send_time") &&
      new Date() < new Date(localStorage?.getItem("send_time") ?? "")
    ) {
      setToastProps({
        title: "Erro ao enviar contato",
        desc: "Ocorreu um erro ao tentar enviar o e-mail, por favor tente novamente!",
        open: true,
      });
      return;
    }
    const serviceId = "service_ecs7u2p";
    const templateId = "template_hal9hg8";
    const userId = "xpeOezaog0z694gxm";
    mutate({ serviceId, templateId, formData, userId });
  };

  useEffect(() => {
    if (toastProps.open) {
      setTimeout(() => {
        setToastProps({ ...toastProps, open: false });
      }, 2500);
    }
  }, [toastProps]);

  return (
    <>
      <Header selected="contact" />
      <Content>
        <ContainerContact>
          <Title text="contato" />
          <PageTitle>vamos conversar!</PageTitle>
          <PageDesc>
            Tem alguma pergunta ou gostaria de falar conosco? Fique à vontade
            para nos enviar uma mensagem! Estamos aqui para ajudar com dúvidas,
            sugestões ou qualquer outra solicitação que responderemos o mais
            rápido possível.
          </PageDesc>
          <BlackBox>
            <BoxBody>
              <BoxTitle>Contato</BoxTitle>
              <BoxInfo>
                <div>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${phoneNumber.replace(
                      /([^0-9])/g,
                      ""
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Whatsapp} alt="whatsapp" /> {phoneNumber}
                  </a>
                  <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
                    <img src={Email} alt="e-mail" /> {email}
                  </a>
                </div>
                <div>
                  <a
                    href={`https://www.instagram.com/${instagram}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Instagram} alt="instagram" /> @{instagram}
                  </a>
                  <a
                    href={`https://www.linkedin.com/company/${linkedin}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="linkedin" /> /{linkedin}
                  </a>
                </div>
              </BoxInfo>
            </BoxBody>
          </BlackBox>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <input
                id="name"
                type="text"
                placeholder="Nome completo *"
                maxLength={50}
                style={{ borderColor: errors.name && "red" }}
                {...register("name")}
              />
              {errors.name && <span>{errors.name.message}</span>}
            </div>
            <div>
              <input
                id="email"
                type="email"
                placeholder="E-mail *"
                maxLength={50}
                style={{ borderColor: errors.email && "red" }}
                {...register("email")}
              />
              {errors.email && <span>{errors.email.message}</span>}
            </div>
            <div>
              <input
                id="phone"
                type="text"
                placeholder="Telefone *"
                maxLength={19}
                style={{ borderColor: errors.phone && "red" }}
                {...register("phone")}
                onChange={(e) => handlePhoneChange(e.target.value)}
              />
              {errors.phone && <span>{errors.phone.message}</span>}
            </div>
            <div>
              <textarea
                id="message"
                cols={30}
                rows={6}
                placeholder="Mensagem"
                maxLength={200}
                {...register("message")}
              ></textarea>
              {errors.message && <span>{errors.message.message}</span>}
            </div>
            <span>
              Ao informar seus dados, você estará ciente das diretrizes de nossa{" "}
              <a href="politica-de-privacidade" target="_blank">
                Política de Privacidade
              </a>
              .
            </span>
            <div>
              <Button
                title="Enviar"
                width="100%"
                secondary
                type="submit"
                isLoading={isLoading}
              />
            </div>
          </form>
        </ContainerContact>
      </Content>
      <Footer />
      <Toast {...toastProps} />
    </>
  );
}
