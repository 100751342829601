import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;

  p {
    text-align: center;

    @media (max-width: 500px) {
      width: 280px;
    }
  }
`;
