import styled from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../theme";

export const PrivacyContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    color: ${COLORS.BLACK};

    ol > li {
      list-style-position: inside;
      margin-top: 24px;
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    }

    ul > li {
      list-style-position: inside;
      padding-left: 16px;
      margin-top: 8px;
    }

    p {
      margin-top: 24px;
    }
  }
`;
