import Container from "../../components/Container";
import Content from "../../components/Content";
import ContentPage from "../../components/ContentPage";
import Header from "../../components/Header";
import BackImg from "../../assets/images/map.webp";
import Footer from "../../components/Footer";

export default function Inter() {
  return (
    <>
      <Header selected="operation" subItem="inter" />
      <Container>
        <Content>
          <ContentPage
            backgroundImage={BackImg}
            cover
            title="Internacionalização de Negócios e Pessoas"
            text="A viabilidade de imigração e globalização de negócios também é uma das áreas de atuação da ENE."
          >
            <h2>Estratégias de Internacionalização e Estudos de Viabilidade</h2>
            <p>
              Na busca por expandir negócios além das fronteiras, elaboramos
              estudos de viabilidade detalhados e arquitetamos estratégias de
              internacionalização que consideram as particularidades culturais e
              as realidades do novo mercado.
            </p>
            <p>
              Nossa abordagem envolve um acompanhamento contínuo da execução do
              Plano Estratégico de colocação no mercado, utilizando uma
              metodologia própria que visa otimizar os resultados e minimizar os
              riscos envolvidos na operação. Dessa forma, garantimos que cada
              passo da internacionalização seja cuidadosamente planejado e
              executado.
            </p>
            <h2>Análise de Investimentos e Migração de Franquias</h2>
            <p>
              Além da internacionalização de empresas, nossa equipe está atenta
              à análise de possíveis investimentos e à migração de franquias.
              Compreendemos que a entrada em novos mercados pode incluir a
              adaptação de modelos de negócios existentes, e estamos preparados
              para oferecer insights valiosos e suporte nessa transição.
            </p>
            <p>
              Isso garante que as franquias se integrem adequadamente ao novo
              ambiente, maximizando as chances de sucesso.
            </p>
            <h2>Apoio à Migração de Famílias e Indivíduos</h2>
            <p>
              Reconhecendo que a internacionalização não se limita apenas ao
              mundo dos negócios, também oferecemos apoio a famílias e pessoas
              que desejam migrar. Nossa equipe está pronta para fornecer
              orientações e recursos que facilitem essa transição, considerando
              aspectos como adaptação cultural, inserção no mercado de trabalho
              e integração social.
            </p>
            <p>
              Nosso compromisso é ajudar a tornar o processo de migração o mais
              suave e bem-sucedido possível, promovendo uma nova vida em um novo
              país.
            </p>
          </ContentPage>
        </Content>
      </Container>
      <Footer />
    </>
  );
}
