import Container from "../../components/Container";
import Content from "../../components/Content";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { PrivacyContent } from "./style";

export default function PrivacyPolicy() {
  return (
    <>
      <Header selected="privacy" />
      <Content>
        <Container>
          <PrivacyContent>
            <Title text="politica de privacidade" />
            <div>
              <p>
                A Ene Escritório de Negócios está comprometida em proteger a
                privacidade dos nossos usuários. Esta Política de Privacidade
                descreve como coletamos, usamos, compartilhamos e protegemos as
                informações pessoais dos nossos usuários.
              </p>
              <p>
                <ol>
                  <li>
                    Informações que Coletamos:
                    <ul>
                      <li>
                        Informações Pessoais: Podemos coletar informações
                        pessoais identificáveis, como nome, endereço de e-mail,
                        número de telefone e informações de pagamento, apenas
                        quando fornecidas voluntariamente pelos usuários.
                      </li>
                      <li>
                        Informações de Navegação: Podemos coletar informações
                        sobre o dispositivo do usuário, incluindo endereço IP,
                        tipo de navegador, páginas visitadas e horários de
                        acesso.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Uso das Informações:{" "}
                    <ul>
                      <li>
                        As informações pessoais fornecidas pelos usuários serão
                        usadas para fornecer os serviços solicitados, processar
                        transações, responder a consultas e enviar comunicações
                        relacionadas à empresa.
                      </li>
                      <li>
                        As informações de navegação podem ser usadas para
                        melhorar a experiência do usuário, personalizar
                        conteúdo, analisar tendências e administrar o site.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Compartilhamento de Informações:
                    <ul>
                      <li>
                        Não compartilhamos informações pessoais dos nossos
                        usuários com terceiros, exceto quando necessário para
                        fornecer serviços solicitados ou conforme exigido por
                        lei.
                      </li>
                      <li>
                        Podemos compartilhar informações agregadas ou
                        anonimizadas que não identificam individualmente os
                        usuários para fins de análise de dados, marketing ou
                        outros fins comerciais.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Segurança das Informações:
                    <ul>
                      <li>
                        Implementamos medidas de segurança para proteger as
                        informações pessoais dos nossos usuários contra acesso
                        não autorizado, uso indevido ou alteração.
                      </li>
                      <li>
                        No entanto, nenhuma transmissão de dados pela internet
                        ou armazenamento eletrônico é totalmente segura, e não
                        podemos garantir a segurança absoluta das informações.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Alterações nesta Política:
                    <ul>
                      <li>
                        Esta Política de Privacidade pode ser atualizada
                        periodicamente para refletir mudanças nas práticas de
                        privacidade. Recomendamos que os usuários revisem esta
                        página regularmente para se manterem informados sobre
                        como protegemos suas informações.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Contato:
                    <ul>
                      <li>
                        Se você tiver alguma dúvida sobre esta Política de
                        Privacidade ou sobre as práticas de privacidade da Ene
                        Escritório de Negócios, entre em contato conosco através
                        do e-mail contato@nnegocios.com.br.
                      </li>
                    </ul>
                  </li>
                </ol>
              </p>
              <p>
                Ao usar o nosso site, você concorda com os termos desta Política
                de Privacidade. Se não concordar com estes termos, por favor,
                não use o nosso site.
              </p>
              <p>
                <small>Atualizado em 19/12/2024.</small>
              </p>
            </div>
          </PrivacyContent>
        </Container>
      </Content>
      <Footer />
    </>
  );
}
